/* eslint-disable react/no-array-index-key */
import Heading from "../../components/ui/Heading";
import Paragraph from "../../components/ui/Paragraph";
import { CMSLink } from "../Link";
import escapeHTML from "escape-html";
import React, { Fragment } from "react";
import { Text } from "slate";

export type Children = Leaf[];

type Leaf = {
  type: string;
  value?: {
    url: string;
    alt: string;
  };
  children?: Children;
  url?: string;
  [key: string]: unknown;
};

const serialize = (children?: Children): React.ReactNode[] =>
  children?.map((node, i) => {
    if (Text.isText(node)) {
      let text = (
        // eslint-disable-next-line react/no-danger
        <span dangerouslySetInnerHTML={{ __html: escapeHTML(node.text) }} />
      );

      if (node.bold) {
        text = <strong key={i}>{text}</strong>;
      }

      if (node.code) {
        text = <code key={i}>{text}</code>;
      }

      if (node.italic) {
        text = <em key={i}>{text}</em>;
      }

      if (node.underline) {
        text = (
          <span style={{ textDecoration: "underline" }} key={i}>
            {text}
          </span>
        );
      }

      if (node.strikethrough) {
        text = (
          <span style={{ textDecoration: "line-through" }} key={i}>
            {text}
          </span>
        );
      }

      return <Fragment key={i}>{text}</Fragment>;
    }

    if (!node) {
      return null;
    }

    switch (node.type) {
      case "h1":
        return (
          <Heading key={i} level={1} type="headline-xl-eb" className="my-6">
            {serialize(node?.children)}
          </Heading>
        );
      case "h2":
        return (
          <Heading key={i} level={2} type="headline-sm-sb" className="my-6">
            {serialize(node?.children)}
          </Heading>
        );
      case "h3":
        return (
          <Heading key={i} level={3} type="headline-xs-sb" className="my-4">
            {serialize(node?.children)}
          </Heading>
        );
      case "h4":
        return <h4 key={i}>{serialize(node?.children)}</h4>;
      case "h5":
        return <h5 key={i}>{serialize(node?.children)}</h5>;
      case "h6":
        return <h6 key={i}>{serialize(node?.children)}</h6>;
      case "quote":
        return <blockquote key={i}>{serialize(node?.children)}</blockquote>;
      case "ul":
        return (
          <ul key={i} className="list-disc list-inside ml-6">
            {serialize(node?.children)}
          </ul>
        );
      case "ol":
        return (
          <ol key={i} className="list-decimal list-inside ml-6">
            {serialize(node.children)}
          </ol>
        );
      case "li":
        return (
          <li key={i} className="text-body-lg mb-2">
            {serialize(node.children)}
          </li>
        );
      case "link":
        return (
          <CMSLink
            key={i}
            type={node.linkType === "internal" ? "reference" : "custom"}
            url={node.url}
            reference={node.doc as never}
            newTab={Boolean(node?.newTab)}
          >
            {serialize(node?.children)}
          </CMSLink>
        );
      default:
        return (
          <Paragraph type="body-lg" className="mb-2" key={i}>
            {serialize(node?.children)}
          </Paragraph>
        );
    }
  }) || [];

export default serialize;

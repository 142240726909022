import { BannerBlock } from "./BannerBlock";
import { CTABlock } from "./CTABlock";
import { ContentBlock } from "./ContentBlock";
import { HeroBlock } from "./HeroBlock";
import { Page } from "@pslifestyle/payload/src/payload-types";

type Props = Pick<Page, "layout">;

const blockComponents = {
  hero: HeroBlock,
  banner: BannerBlock,
  cta: CTABlock,
  content: ContentBlock,
};

export const Blocks = ({ layout }: Props) => {
  const hasBlocks = layout && Array.isArray(layout) && layout.length > 0;
  if (hasBlocks) {
    return (
      <>
        {layout.map((block) => {
          const { id, blockType } = block;
          const Block = blockComponents[blockType];
          if (id && Block) {
            // @ts-expect-error TS will complain about possibly undefined props
            return <Block key={id} {...block} />;
          }
          return null;
        })}
      </>
    );
  }
  return null;
};

import { FullWidthContainer } from "../../common/components/layout/Container";
import Heading from "../../common/components/ui/Heading";
import Paragraph from "../../common/components/ui/Paragraph";
import BackButtonInContainer from "../../common/components/ui/buttons/BackButtonInContainer";
import { useEffect, useState, ReactNode } from "react";
import { useTranslation } from "react-i18next";

const listContentType = "paragraphListItem";

interface IRendererProps {
  children: ReactNode;
}

const getContentType = (contentType: string): string => {
  if (contentType.indexOf("_") < 0) {
    return contentType;
  }

  const indexOfUnderscore = contentType.indexOf("_");
  return contentType.slice(0, indexOfUnderscore);
};
const preparePrivacyPolicyStructure = (
  flatTranslationKeysToRender: string[],
): Array<string | string[]> => {
  const structuredKeys: Array<string | string[]> = [];
  let elementsCount = 0;

  flatTranslationKeysToRender.forEach((translationKey) => {
    const contentType = getContentType(translationKey);

    if (contentType === listContentType) {
      const lastItem = structuredKeys[elementsCount - 1];
      if (!Array.isArray(lastItem)) {
        structuredKeys.push([translationKey]);
        elementsCount += 1;
      } else {
        lastItem.push(translationKey);
      }

      return;
    }

    structuredKeys.push(translationKey);
    elementsCount += 1;
  });

  return structuredKeys;
};

const MainTitleRenderer = ({ children }: IRendererProps) => (
  <Heading level={1} type="headline-xl-eb" className="my-6">
    {children}
  </Heading>
);
const SecondaryTitleRenderer = ({ children }: IRendererProps) => (
  <Heading level={2} type="headline-sm-sb" className="my-6">
    {children}
  </Heading>
);
const ParagraphTitleRenderer = ({ children }: IRendererProps) => (
  <Heading level={3} type="headline-xs-sb" className="ml-4 my-4">
    {children}
  </Heading>
);
const ParagraphTextRenderer = ({ children }: IRendererProps) => (
  <Paragraph type="body-lg" className="ml-4">
    {children}
  </Paragraph>
);
const renderers: { [key: string]: (props: IRendererProps) => JSX.Element } = {
  mainTitle: MainTitleRenderer,
  secondaryTitle: SecondaryTitleRenderer,
  paragraphTitle: ParagraphTitleRenderer,
  paragraphText: ParagraphTextRenderer,
};

const ListRenderer = ({
  keysToRender,
  group,
}: {
  keysToRender: string[];
  group: string;
}) => {
  const { t } = useTranslation(["common", "staticTranslations"]);

  return (
    <ul className="list-disc list-inside ml-6">
      {keysToRender.map((key) => (
        <li key={`${group}.${key}`} className="text-body-lg mb-2">
          {t(`${group}.${key}`, {
            ns: "staticTranslations",
          })}
        </li>
      ))}
    </ul>
  );
};

interface IPrintContentProps {
  keysToRender: Array<string | string[]>;
  group: string;
}

const PrintContent = ({ keysToRender, group }: IPrintContentProps) => {
  const { t } = useTranslation(["common", "staticTranslations"]);

  return (
    <>
      {keysToRender.map((keyToRender) => {
        if (Array.isArray(keyToRender)) {
          return (
            <ListRenderer
              key={`${group}.${keyToRender}`}
              keysToRender={keyToRender}
              group={group}
            />
          );
        }

        const contentType = getContentType(keyToRender);
        const Renderer = renderers[contentType];

        if (!Renderer) {
          console.log(`Cannot render type ${contentType}`);

          return null;
        }

        return (
          <Renderer key={`${group}.${keyToRender}`}>
            {t(`${group}.${keyToRender}`, { ns: "staticTranslations" })}
          </Renderer>
        );
      })}
    </>
  );
};

const PrivacyNoticePage = (): JSX.Element => {
  const [state, setState] = useState<{
    summaryKeys: Array<string | string[]>;
    fullPolicyKeys: Array<string | string[]>;
  }>({
    summaryKeys: [],
    fullPolicyKeys: [],
  });
  const { i18n } = useTranslation(["common", "staticTranslations"]);

  useEffect(() => {
    const privacyPolicyTranslations = i18n.getResourceBundle(
      i18n.language,
      "staticTranslations",
    );
    const summaryKeys = preparePrivacyPolicyStructure(
      Object.keys(privacyPolicyTranslations.privacyPolicySummary),
    );
    const fullPolicyKeys = preparePrivacyPolicyStructure(
      Object.keys(privacyPolicyTranslations.privacyPolicyFull),
    );
    setState({ summaryKeys, fullPolicyKeys });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <>
      <BackButtonInContainer />
      <FullWidthContainer>
        <article data-testid="article-summary">
          <PrintContent
            keysToRender={state.summaryKeys}
            group="privacyPolicySummary"
          />
        </article>
        <article data-testid="article-full">
          <PrintContent
            keysToRender={state.fullPolicyKeys}
            group="privacyPolicyFull"
          />
        </article>
      </FullWidthContainer>
    </>
  );
};
export default PrivacyNoticePage;

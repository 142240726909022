import { PAYLOAD_URL } from "../../app/config";
import { Media } from "@pslifestyle/payload/src/payload-types";
import classNames from "classnames";

export const CMSImage = ({
  image,
  className,
}: {
  image: string | Media | null | undefined;
  className?: string;
}) => {
  if (image) {
    const media = image as Media;
    return (
      <img
        className={classNames(className, "object-cover")}
        src={`${PAYLOAD_URL}${media.url}`}
        alt={media.alt}
      />
    );
  }
  return null;
};

import { WideWidthContainer } from "../../common/components/layout/Container";
import Heading from "../../common/components/ui/Heading";
import { Button } from "../../common/components/ui/buttons/Button";
import { colors } from "../../common/utils/helpers";
import { Category } from "@pslifestyle/common/src/types/genericTypes";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const choiceColors: {
  [key in Category]: string;
} = {
  housing: "orange",
  transport: "purple",
  food: "pink",
  purchases: "cyan",
  demographic: "green",
};

interface QuestionChoicePreviewProps {
  category: Category;
  text: string;
  active?: boolean;
  onClick: () => void;
}

const QuestionChoicePreview = ({
  category,
  text,
  active,
  onClick,
}: QuestionChoicePreviewProps) => {
  const color = choiceColors[category];
  const colorClass = `
      bg-neutral-white
      border-${color}-40
      hover:bg-${color}-40
      hover:border-${color}-60
      ${active ? `bg-${color}-80 border-${color}-100` : "bg-neutral-white"}
      `;
  return (
    <Button
      onClick={onClick}
      className={`
      container
      inline-flex
      justify-center
      items-center
      w-full
      py-3
      px-6
      rounded
      border-2
      leading-5
      text-body-md
      ${colorClass}
      `}
    >
      {text}
    </Button>
  );
};

export interface QuestionPreviewProps {
  category: Category;
  text: string;
  description?: string;
  choices: string[];
}

export const QuestionPreview = ({
  category,
  text,
  description,
  choices,
}: QuestionPreviewProps) => {
  const { t } = useTranslation(["common"]);
  const [selected, setSelected] = useState("");

  const categoryColor = colors[category];
  return (
    <WideWidthContainer className="px-4 mb-4">
      <Heading
        level={2}
        type="headline-lg-eb"
        className={`mt-4 mb-2 text-center text-${categoryColor}-100`}
      >
        {t(`categories.${category}`)}
      </Heading>
      <div className="flex flex-col justify-center items-center child:pb-6">
        <Heading level={1} type="headline-sm-b" className="text-center">
          {text}
        </Heading>
        {description && <p className="max-w-3xl">{description}</p>}
      </div>
      <div className="w-full flex flex-col items-center gap-2">
        {choices.map((choice) => (
          <QuestionChoicePreview
            key={choice}
            category={category}
            text={choice}
            active={selected === choice}
            onClick={() => setSelected(selected !== choice ? choice : "")}
          />
        ))}
      </div>
    </WideWidthContainer>
  );
};

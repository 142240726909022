import { fiveMinutesInterval } from "../common/utils/helpers";

// @ts-expect-error No need to know fetcher arg types
export const fetcher = (...args) => fetch(...args).then((res) => res.json());

export const defaultSWRConfig = {
  revalidateIfStale: false,
  refreshInterval: fiveMinutesInterval,
  errorRetryCount: 0,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

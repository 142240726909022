import { FullWidthContainer } from "../components/layout/Container";
import BackButtonInContainer from "../components/ui/buttons/BackButtonInContainer";
import { Blocks } from "./Blocks";
import { Page as PayloadPage } from "@pslifestyle/payload/src/payload-types";

export const CMSPage = ({
  page: { layout, enableBackButton },
}: {
  page: PayloadPage;
}) => (
  <div>
    {enableBackButton && <BackButtonInContainer />}
    <FullWidthContainer>
      <Blocks layout={layout} />
    </FullWidthContainer>
  </div>
);

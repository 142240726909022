import ImpactActionCardCorner from "../../common/components/ActionCard/ActionCardCorner/ImpactActionCardCorner";
import { WideWidthContainer } from "../../common/components/layout/Container";
import Heading from "../../common/components/ui/Heading";
import Tag from "../../common/components/ui/Tag";
import { TextLinkButton } from "../../common/components/ui/buttons/TextLinkButton";
import {
  ActionCategory,
  TagTheme,
} from "@pslifestyle/common/src/types/planTypes";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export interface ActionPreviewProps {
  category: ActionCategory;
  title: string;
  description: string;
}

export const ActionPreview = ({
  category,
  title,
  description,
}: ActionPreviewProps) => {
  const { t } = useTranslation("recommendations");
  const [showMore, setShowMore] = useState(false);

  return (
    <WideWidthContainer className="px-4 mb-4">
      <div className="bg-basic-white rounded-2xl">
        <div className="flex flex-row">
          <div className="grow pt-6 pl-5 pb-1">
            <Tag
              filter={category.toLowerCase()}
              key={category}
              variant={category.toLowerCase() as TagTheme}
            >
              {t(`categories.${category.toLowerCase()}`, {
                ns: "common",
              })}
            </Tag>
            <Heading level={3} type="title-md" className="mt-3">
              {title}
            </Heading>
            <TextLinkButton
              onClick={() => setShowMore(!showMore)}
              icon={{
                position: "right",
                size: "medium",
                type: showMore ? "ChevronUp" : "ChevronDown",
              }}
              buttonClassName="mt-2.5"
            >
              {showMore ? t("hide", { ns: "common" }) : t("seeMore")}
            </TextLinkButton>
          </div>
          <div>
            <ImpactActionCardCorner
              category={category}
              calculatedImpact={100}
              percentReduction={50}
            />
          </div>
        </div>
        <div
          className={`ml-3 mb-3 overflow-hidden transition-max-height ease-in-out duration-300 ${
            showMore ? "max-h-screen" : "max-h-0"
          }`}
        >
          <div className="flex flex-col gap-1 px-2 mb-2 body-md">
            {description}
          </div>
        </div>
      </div>
    </WideWidthContainer>
  );
};

import { buildQuery, getDocs } from "../FirestoreClient";
import { defaultSWRConfig } from "../swrConfig";
import { queryLatestEntityVersion } from "./common";
import { Page } from "@pslifestyle/payload/src/payload-types";
import { where } from "firebase/firestore";
import useSWR from "swr";

const fetchPage = async (
  slug: string,
  countryCode: string,
  language: string,
) => {
  const collectionName = "pages";
  try {
    const pagesVersion = await queryLatestEntityVersion(collectionName);
    const queryPath = `${collectionName}/${pagesVersion}/${countryCode}/`;
    const pageQuery = await buildQuery(
      queryPath,
      where("slug", "==", slug),
      where("language.code", "==", language),
    );
    const queryResults = await getDocs(pageQuery);
    const pages: Page[] = queryResults.docs
      .filter(Boolean)
      .map((page) => page.data() as Page);
    if (pages.length === 0) {
      return null;
    }
    return pages[0];
  } catch (e) {
    console.error(
      `Failed to fetch page ${slug} for ${countryCode}/${language} from Firestore`,
      e,
    );
  }
  return null;
};

export const usePage = (
  slug?: string,
  countryCode?: string,
  language?: string | null,
) =>
  useSWR(
    slug && countryCode && language
      ? ["page", slug, countryCode, language]
      : null,
    ([_, slug, countryCode, language]) =>
      fetchPage(slug, countryCode, language),
    defaultSWRConfig,
  );

import { RichText } from "./RichText/RichText";
import { Content } from "@pslifestyle/payload/src/payload-types";
import React from "react";

type Props = Pick<Content, "richText">;

export const ContentBlock: React.FC<Props> = ({ richText }) => (
  <div>
    <RichText content={richText as never} />
  </div>
);

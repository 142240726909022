import { PAYLOAD_URL } from "../../app/config";
import { CMSPage } from "../../common/cms/CMSPage";
import { FullPageLoader } from "../../common/components/loaders/FullPageLoader";
import { useLivePreview } from "@payloadcms/live-preview-react";
import { Page as PayloadPage } from "@pslifestyle/payload/src/payload-types";

export const ContentPreviewPage = ({
  page: initialPage,
}: {
  page?: PayloadPage | null | undefined;
}) => {
  const { data, isLoading } = useLivePreview({
    initialData: initialPage,
    serverURL: PAYLOAD_URL,
    depth: 1,
  });

  if (isLoading || !data) {
    return <FullPageLoader />;
  }

  return <CMSPage page={data} />;
};

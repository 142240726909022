import { Country } from "./countries";

export const languagesList = [
  "en-GB",
  "fi-FI",
  "sv-FI",
  "et-EE",
  "el-GR",
  "tr-TR",
  "de-DE",
  "pt-PT",
  "it-IT",
  "nb-NO",
  "sl-SI",
  "ru-RU",
  "pl-PL",
];

export const englishNameToLanguageCode: Record<string, string> = {
  English: "en-GB",
  Finnish: "fi-FI",
  Swedish: "sv-FI",
  German: "de-DE",
  Estonian: "et-EE",
  Greek: "el-GR",
  Italian: "it-IT",
  Norwegian: "nb-NO",
  Portuguese: "pt-PT",
  Russian: "ru-RU",
  Slovene: "sl-SI",
  Turkish: "tr-TR",
  Polish: "pl-PL",
};

export const defaultLanguageName = "English";

export type LanguageType = {
  nativeName: string;
};

export type LanguagesObjType = {
  [key: string]: LanguageType;
};

export interface LanguageOptionType {
  label: string;
  value: string;
}

export const defaultLanguageCode = "en-GB";

export const languagesConfig: LanguagesObjType = {
  "en-GB": { nativeName: "English" },
  "fi-FI": { nativeName: "Suomi" },
  "sv-FI": { nativeName: "Svenska" },
  "de-DE": { nativeName: "Deutsch" },
  "et-EE": { nativeName: "Eesti" },
  "el-GR": { nativeName: "ελληνικά" },
  "it-IT": { nativeName: "Italiano" },
  "nb-NO": { nativeName: "Norsk bokmål" },
  "pt-PT": { nativeName: "Português" },
  "ru-RU": { nativeName: "Pу́сский" },
  "sl-SI": { nativeName: "Slovenščina" },
  "tr-TR": { nativeName: "Türkçe" },
  "pl-PL": { nativeName: "Polski" },
};

export const LANGUAGE_OPTIONS: LanguageOptionType[] = Object.keys(
  languagesConfig,
).reduce(
  (acc: LanguageOptionType[], languageCode) => [
    ...acc,
    { label: languagesConfig[languageCode].nativeName, value: languageCode },
  ],
  [],
);

export const languageOptionsForCountry = (country: Country) => {
  return country.languages.map((languageCode: string) =>
    LANGUAGE_OPTIONS.find((option) => option.value === languageCode),
  ) as LanguageOptionType[];
};

export const getLanguageNativeName = (language: string | null): string => {
  try {
    return languagesConfig[language as string]?.nativeName ?? "";
  } catch {
    return "";
  }
};

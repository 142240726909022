import { PAYLOAD_URL } from "../../app/config";
import { WideWidthContainer } from "../../common/components/layout/Container";
import { FullPageLoader } from "../../common/components/loaders/FullPageLoader";
import { ActionPreview, ActionPreviewProps } from "./ActionPreview";
import { FeedbackCardsPreview } from "./FeedbackCardPreview";
import { QuestionPreview, QuestionPreviewProps } from "./QuestionPreview";
import { useLivePreview } from "@payloadcms/live-preview-react";
import { Category } from "@pslifestyle/common/src/types/genericTypes";
import { ActionCategory } from "@pslifestyle/common/src/types/planTypes";
import {
  FeedbackCards as PayloadFeedbackCards,
  Actions as PayloadActions,
  Region as PayloadRegion,
  Questions as PayloadQuestions,
  Category as PayloadCategory,
  Language,
  LocalizedTexts,
} from "@pslifestyle/payload/src/payload-types";
import { useState } from "react";
import Select from "react-select";

type ArrayElement<ArrayType extends readonly unknown[] | null> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;
type PayloadQuestion = ArrayElement<PayloadQuestions>;
type PayloadAction = ArrayElement<PayloadActions>;

function getLocalizedText(
  languageCode: string,
  defaultText?: string,
  localizedTexts?: LocalizedTexts,
) {
  return (
    localizedTexts?.find((text) => {
      const payloadLanguage = text.language as Language;
      return payloadLanguage?.code === languageCode;
    })?.text ||
    defaultText ||
    ""
  );
}

function getQuestionPreviewProps(
  question: PayloadQuestion,
  languageCode: string,
): QuestionPreviewProps {
  const {
    category: payloadCategory,
    text,
    localizedQuestionTexts,
    description,
    localizedDescriptions,
    answers,
  } = question;
  const category = (payloadCategory as PayloadCategory).name.toLowerCase();

  return {
    category: category as Category,
    text: getLocalizedText(languageCode, text, localizedQuestionTexts),
    description: getLocalizedText(
      languageCode,
      description || undefined,
      localizedDescriptions,
    ),
    choices:
      answers?.map(({ text, localizedAnswerTexts }) =>
        getLocalizedText(languageCode, text, localizedAnswerTexts),
      ) || [],
  };
}
function getActionPreviewProps(
  action: PayloadAction,
  languageCode: string,
): ActionPreviewProps {
  const {
    category: payloadCategory,
    title,
    localizedActionTitles,
    description,
    localizedActionDescriptions,
  } = action;
  const category = (payloadCategory as PayloadCategory).name.toLowerCase();

  return {
    category: category as ActionCategory,
    title: getLocalizedText(languageCode, title, localizedActionTitles),
    description: getLocalizedText(
      languageCode,
      description || undefined,
      localizedActionDescriptions,
    ),
  };
}

function getFeedbackCardsPreviewProps(
  feedbackCards: PayloadFeedbackCards,
  languageCode: string,
) {
  return {
    texts:
      feedbackCards?.map(({ text, localizedTexts }) =>
        getLocalizedText(languageCode, text, localizedTexts),
      ) || [],
  };
}

export const RegionPreviewPage = ({
  region: initialRegion,
}: {
  region?: PayloadRegion | null | undefined;
}) => {
  const { data, isLoading } = useLivePreview({
    initialData: initialRegion,
    serverURL: PAYLOAD_URL,
    depth: 1,
  });

  const [language, setLanguage] = useState<string | undefined>();

  if (isLoading || !data) {
    return <FullPageLoader />;
  }

  const regionLanguages = data.languages as Language[];
  const languageOptions = regionLanguages.map((language) => ({
    value: language.code,
    label: language.code,
  }));
  const defaultOption = { value: "en-GB", label: "en-GB" };
  const languageCode = language || defaultOption.value;

  return (
    <div>
      <WideWidthContainer className="px-4 mb-4">
        <p>Select language:</p>
        <Select
          options={[defaultOption, ...languageOptions]}
          defaultValue={defaultOption}
          onChange={(value) => setLanguage(value?.value)}
        />
      </WideWidthContainer>

      {data?.questions?.map((question) => (
        <QuestionPreview
          key={question.id}
          {...getQuestionPreviewProps(question, languageCode)}
        />
      ))}

      {data?.actions?.map((action) => (
        <ActionPreview
          key={action.id}
          {...getActionPreviewProps(action, languageCode)}
        />
      ))}

      <FeedbackCardsPreview
        {...getFeedbackCardsPreviewProps(
          data?.feedbackCards || [],
          languageCode,
        )}
      />
    </div>
  );
};

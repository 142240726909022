import { locationSelectors } from "../../features/location/locationSlice";
import { usePage } from "../../firebase/db/pages";
import { parseLanguageCode } from "../../i18n/utils";
import { FullPageLoader } from "../components/loaders/FullPageLoader";
import NotFoundPage from "../pages/NotFoundPage";
import { CMSPage } from "./CMSPage";
import { Page as PayloadPage } from "@pslifestyle/payload/src/payload-types";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

export const ContentPage = () => {
  const location = useLocation();
  const country = useSelector(locationSelectors.country);
  const language = useSelector(locationSelectors.language);
  const { languageCode } = parseLanguageCode(language);
  const countryCode = country?.code;
  const slug = location.pathname.split("/")[1];
  const { data, isLoading } = usePage(slug, countryCode, languageCode);

  if (!country || !languageCode) {
    return <Navigate to="/selections" />;
  }
  if (isLoading) {
    return <FullPageLoader />;
  }
  const page = data as PayloadPage;
  if (!page) {
    return <NotFoundPage />;
  }

  return <CMSPage page={page} />;
};

import { GoToQuestionnaireButton } from "../../features/questionnaire/GoToQuestionnaireButton";
import Heading from "../components/ui/Heading";
import { RichText } from "./RichText/RichText";
import { CallToAction } from "@pslifestyle/payload/src/payload-types";
import React from "react";

type Props = Pick<CallToAction, "heading" | "text">;

export const CTABlock: React.FC<Props> = ({ heading, text }) => (
  <div className="flex flex-col md:items-center gap-3 child:md:basis-1/2 py-3">
    {heading && (
      <Heading level={2} type="headline-md-b" className="my-4">
        {heading}
      </Heading>
    )}
    {text && text.length > 0 && <RichText content={text} />}
    <GoToQuestionnaireButton />
  </div>
);

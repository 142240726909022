export type Country = { name: string; code: string; languages: string[] };

export const countries: Country[] = [
  {
    name: "Europe",
    code: "EU",
    languages: ["en-GB"],
  },
  {
    name: "Estonia",
    code: "EE",
    languages: ["et-EE", "ru-RU", "en-GB"],
  },
  {
    name: "Finland",
    code: "FI",
    languages: ["fi-FI", "en-GB", "sv-FI"],
  },
  {
    name: "Germany",
    code: "DE",
    languages: ["de-DE", "en-GB"],
  },
  {
    name: "Greece",
    code: "GR",
    languages: ["el-GR", "en-GB"],
  },
  {
    name: "Italy",
    code: "IT",
    languages: ["it-IT", "en-GB"],
  },
  {
    name: "Norway",
    code: "NO",
    languages: ["nb-NO", "en-GB"], // Bokmål
  },
  {
    name: "Poland",
    code: "PL",
    languages: ["pl-PL", "en-GB"],
  },
  {
    name: "Portugal",
    code: "PT",
    languages: ["pt-PT", "en-GB"],
  },
  {
    name: "Slovenia",
    code: "SI",
    languages: ["sl-SI", "en-GB"],
  },
  {
    name: "Turkey",
    code: "TR",
    languages: ["tr-TR", "en-GB"],
  },
];

export const countryCodes = countries.map((country) => country.code);
export const countryLanguages = countries.reduce(
  (acc, country) =>
    acc.concat(country.languages.map((lang) => `${lang}:${country.code}`)),
  [] as string[],
);

export function getCountryObjectByCode(code: string) {
  return countries.find((country) => country.code === code);
}

import serialize, { Children } from "./serialize";
import React from "react";

export const RichText: React.FC<{ className?: string; content: unknown }> = ({
  className,
  content,
}) => {
  if (!content) {
    return null;
  }

  return <div className={className}>{serialize(content as Children)}</div>;
};

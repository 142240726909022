import { Icon } from "../ui/Icon";

const DevEnvBanner = () => {
  const showDevEnvBanner =
    window.location.href.includes("dev.pslifestyle-app.net") ||
    window.location.href.includes(
      "hanki-2631-pslifestyle-dev.firebaseapp.com",
    ) ||
    window.location.href.includes("hanki-2631-pslifestyle-dev.web.app");

  return showDevEnvBanner ? (
    <div className="min-h-14 w-full flex items-center bg-pink-80 border-4 border-pink-dark">
      <div className="p-1">
        <Icon size="medium" type="AlertCircle" />
      </div>
      <p className="p-1 text-center text-pink-dark">
        <span className="font-bold">NOTE: DEV ENVIRONMENT.</span> This version
        of the PSLifestyle app is used by developers for testing. Production
        environment available here:{" "}
        <span className="font-bold">
          <a href="https://pslifestyle-app.net/">pslifestyle-app.net</a>
        </span>
        . <span className="font-bold">DO NOT USE - DEV ENVIRONMENT!</span>
      </p>
    </div>
  ) : null;
};

export default DevEnvBanner;

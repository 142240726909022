import { ButtonLarge } from "../../ui/buttons";
import { IThemedButtonProps } from "../../ui/buttons/ThemedButton";
import { ForwardedRef, forwardRef } from "react";

type IButtonMediumProps = IThemedButtonProps;

const MainActionCardButton = forwardRef(
  (
    { className, children, ...restProps }: IButtonMediumProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <ButtonLarge
      {...restProps}
      className={`h-[46px] mx-5 mb-5 flex justify-center font-bold rounded-full !flex group-focus:mx-5 group-focus:mb-5 group-focus:mt-0 ${
        className || ""
      }`}
      ref={ref}
    >
      {children}
    </ButtonLarge>
  ),
);

export default MainActionCardButton;

import { AbstractLogo } from "../../features/home/AbstractLogo";
import { GoToQuestionnaireButton } from "../../features/questionnaire/GoToQuestionnaireButton";
import { FullWidthContainer } from "../components/layout/Container";
import Heading from "../components/ui/Heading";
import { Icon } from "../components/ui/Icon";
import Paragraph from "../components/ui/Paragraph";
import { randomInt } from "@pslifestyle/common/src/helpers/securedMathjs";
import { Hero } from "@pslifestyle/payload/src/payload-types";
import React from "react";
import * as FeatherIcon from "react-feather";

type Props = Pick<Hero, "heading" | "bullets" | "showStartButton">;
const colorOptions = [
  "bg-orange-100",
  "bg-green-100",
  "bg-pink-100",
  "bg-cyan-100",
];

const BulletItem = ({
  icon,
  text,
}: {
  icon: keyof typeof FeatherIcon;
  text: string;
}) => (
  <div className="flex items-start">
    <Icon size="large" type={icon} className="pr-1 text-neutral-white" />
    <Paragraph className="text-neutral-white mt-1" type="body-lg-b">
      {text}
    </Paragraph>
  </div>
);

export const HeroBlock: React.FC<Props> = ({
  heading,
  bullets,
  showStartButton,
}) => {
  const bgColor = colorOptions[randomInt(colorOptions.length)];
  return (
    <div
      className={`py-8 mb-3 ${bgColor}`}
      style={{
        marginLeft: "calc(50% - 50vw)",
        marginRight: "calc(50% - 50vw)",
      }}
    >
      <FullWidthContainer className="flex flex-col gap-3">
        <div className="flex justify-center">
          <AbstractLogo />
        </div>
        <Heading level={3} type="headline-lg-eb" className="text-neutral-white">
          {heading}
        </Heading>
        {bullets?.map(({ icon, text }) => (
          <BulletItem icon={icon} text={text} />
        ))}
        {showStartButton && <GoToQuestionnaireButton />}
      </FullWidthContainer>
    </div>
  );
};

import { Page } from "@pslifestyle/payload/src/payload-types";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

type CMSLinkType = {
  type?: "custom" | "reference";
  url?: string;
  newTab?: boolean;
  reference?: {
    value: string | Page;
    relationTo: "pages";
  };
  children?: React.ReactNode;
  className?: string;
};

function handleUrl(url?: string) {
  if (!url) {
    return null;
  }
  const splitByProtocol = url.split("//");
  const plainUrl = splitByProtocol[1] || splitByProtocol[0];
  return `//${plainUrl}`;
}

export const CMSLink: React.FC<CMSLinkType> = ({
  type,
  url,
  newTab,
  reference,
  children,
  className,
}) => {
  const href =
    type === "reference" &&
    typeof reference?.value === "object" &&
    reference.value.slug
      ? `${reference?.relationTo !== "pages" ? `/${reference?.relationTo}` : ""}/${
          reference.value.slug
        }`
      : handleUrl(url);

  if (!href) return null;

  const newTabProps = newTab
    ? { target: "_blank", rel: "noopener noreferrer" }
    : {};

  return (
    <Link
      {...newTabProps}
      to={href}
      className={classNames("underline", className)}
    >
      {children && children}
    </Link>
  );
};

import { WideWidthContainer } from "../../common/components/layout/Container";
import Checkbox from "../../common/components/ui/Checkbox";
import React, { useState } from "react";

export interface ActionPreviewProps {
  texts: string[];
}

export const FeedbackCardsPreview = ({ texts }: ActionPreviewProps) => {
  const [selected, setSelected] = useState<Set<string>>(new Set());

  return (
    <WideWidthContainer className="px-4 mb-4">
      <div className="bg-neutral-white rounded-xl">
        <ul className="my-8 overflow-y-auto flex-grow">
          {texts.map((text) => (
            <li
              key={text}
              className="border-t border-transparent-black last:border-b"
            >
              <Checkbox
                checked={selected.has(text)}
                onChange={() =>
                  setSelected((prev) => {
                    const selected = new Set(prev);
                    if (selected.has(text)) {
                      selected.delete(text);
                    } else {
                      selected.add(text);
                    }
                    return selected;
                  })
                }
              >
                {text}
              </Checkbox>
            </li>
          ))}
        </ul>
      </div>
    </WideWidthContainer>
  );
};

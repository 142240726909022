import Heading from "../components/ui/Heading";
import { Icon } from "../components/ui/Icon";
import { CMSImage } from "./CMSImage";
import { RichText } from "./RichText/RichText";
import { Banner } from "@pslifestyle/payload/src/payload-types";
import React from "react";

type Props = Pick<Banner, "heading" | "icon" | "text" | "image" | "direction">;

export const BannerBlock: React.FC<Props> = ({
  heading,
  icon,
  text,
  image,
  direction,
}) => {
  const flexDirection = direction === "default" ? "" : "flex-row-reverse";
  return (
    <div
      className={`flex flex-start ${flexDirection} gap-3 child:md:w-1/2 py-3`}
    >
      <CMSImage className="hidden md:block h-[250px] my-auto" image={image} />
      <div className="my-auto space-y-2">
        <div className="flex items-center">
          <Heading
            level={2}
            type="headline-md-b"
            className="text-green-dark grow"
          >
            {heading}
          </Heading>
          {icon && (
            <Icon
              size="extralarge"
              type={icon}
              className="pr-1 text-green-dark"
            />
          )}
        </div>
        {text && <RichText content={text} />}
      </div>
    </div>
  );
};

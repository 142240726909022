import { QuestionTheme } from "../../../../features/questionnaire/QuestionnaireUtils";
import { colors } from "../../../utils/helpers";
import ActionCardCornerContainer from "./ActionCardCornerContainer";
import { ActionCategory } from "@pslifestyle/common/src/types/planTypes";

interface Props {
  category: ActionCategory;
  calculatedImpact: number;
  percentReduction: number;
}

const ImpactActionCardCorner = ({
  category,
  calculatedImpact,
  percentReduction,
}: Props) => {
  const impactPercentageOnTotalFootprint = percentReduction.toFixed(1);

  return (
    <ActionCardCornerContainer
      variant={category.toLowerCase() as QuestionTheme}
    >
      <span
        className={`headline-xs-sb text-${colors[category.toLowerCase()]}-dark`}
      >
        -{impactPercentageOnTotalFootprint}%
      </span>
      <span className="body-sm text-center leading-4 mt-px">
        {calculatedImpact.toFixed()} kgCO<sub>2</sub>e
      </span>
    </ActionCardCornerContainer>
  );
};

export default ImpactActionCardCorner;
